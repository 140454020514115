import FeatureFlagService from 'src/services/FeatureFlagService';
import {Platforms} from '@ionic/core';
import {Capacitor} from '@capacitor/core';
import {isPlatform} from '@ionic/react';
import {getMedia} from '../utils/mq';

export enum AppPlatform {
  BASIC = 'BASIC',
  IONIC = 'IONIC',
}

/**
 * Stores latest platform in local storage
 */
export default class PlatformService {
  private _canViewIonic: boolean | undefined;
  private _useIonicForMobile: boolean | undefined;
  private _localStorageKey = 'lastViewedPlatform';

  async canViewIonic(): Promise<boolean> {
    if (Capacitor.getPlatform() !== 'web') {
      this._canViewIonic = true;
      return this._canViewIonic;
    }

    if (!this._canViewIonic) {
      this._canViewIonic = await FeatureFlagService.getFeatureFlagStatus('canViewIonicApplication');
    }

    return this._canViewIonic;
  }

  async useIonicForMobile(): Promise<boolean> {
    if (this._useIonicForMobile === undefined) {
      this._useIonicForMobile = await FeatureFlagService.getFeatureFlagStatus('useIonicForMobile');
    }

    return this._useIonicForMobile;
  }

  private getStoredPlatform(): AppPlatform | null {
    const storageValue = window.localStorage.getItem(this._localStorageKey) as AppPlatform;
    if ([AppPlatform.BASIC, AppPlatform.IONIC].includes(storageValue)) {
      return storageValue;
    }

    return null;
  }

  async setPlatform(p: AppPlatform): Promise<AppPlatform> {
    if (await this.canViewIonic()) {
      window.localStorage.setItem(this._localStorageKey, p);
    }

    return this.getPlatform();
  }

  async getPlatform(): Promise<AppPlatform> {
    const isIonicBuild = process.env.IONIC_BUILD === '1';

    if (isIonicBuild) {
      return AppPlatform.IONIC;
    }

    const mq = getMedia();
    if (mq.SM_AND_DOWN && (await this.useIonicForMobile())) {
      return AppPlatform.IONIC;
    }

    return (await this.getStoredPlatform()) || AppPlatform.BASIC;
  }

  public static isNativeApp(): boolean {
    return process.env.IONIC_BUILD === '1';
  }

  public static runtime(): 'ios' | 'android' | 'web' {
    if (this.isNativeApp()) {
      if (isPlatform('ios')) {
        return 'ios';
      } else {
        return 'android';
      }
    }

    return 'web';
  }

  public static isIonicPlatform(platform: Platforms): boolean {
    return isPlatform(platform);
  }

  public static isMobileWeb(): boolean {
    return isPlatform('mobileweb');
  }
}
