import {EducationPhase, UserIdentifier} from 'src/types/enums';
import PlatformService from '../services/PlatformService';

export const getUserIdentifier = (
  userType: string,
  educationPhase: EducationPhase
): UserIdentifier =>
  [userType, educationPhase]
    // Some user types have no education phase
    .filter(Boolean)
    .join(':')
    .toLowerCase() as UserIdentifier;

export const mobileAllowedUserTypes = () => {
  const userTypes = ['Student', 'Parent'];

  if (!PlatformService.isNativeApp()) {
    userTypes.push('CollegePortalUser', 'Educator', 'DistrictAdmin', 'SuperAdmin');
  }

  return userTypes.join(',');
};
