import JWTService from 'src/services/JWTService';
import superagent from 'superagent/dist/superagent';
import {EducationPhase, UserIdentifier} from 'src/types/enums';
import qs from 'qs';
import {uniq} from 'lodash';
import {getUserIdentifier} from 'src/utils/users';
import UserService from 'src/services/UserService';
import vars from 'src/utils/vars';
import {successfulLoginRedirectPath} from 'src/utils/storage';
import {getElementOnReady} from 'src/utils/dom';
import {setTraceAttributes} from 'src/utils/opentelemetry/traceAttributes';
import PlatformService from '../services/PlatformService';

export interface UserSession<Fields = object> {
  userIdentifier: UserIdentifier;
  next: string;
  user: Fields & {
    id: string;
    type: string;
    educationPhase: EducationPhase;
  };
}

export interface ValidateSessionResponse {
  next: string;
  user: {_id: string; type: string; educationPhase: EducationPhase};
  token: string;
}

export function logout() {
  JWTService.userToken.remove();

  // these are cookies we set in the browser to handle the session, deleting them here to make sure user gets fully logged out
  document.cookie = '_raiseme_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie =
    '_raiseme_skip_static_homepage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  successfulLoginRedirectPath.set(location.pathname);

  if (PlatformService.isNativeApp()) {
    location.assign('/login');
  } else {
    location.href = '/logout';
  }
}

export function handleUnauthorizedRequest(redirect = true): void {
  JWTService.userToken.remove();

  // these are cookies we set in the browser to handle the session, deleting them here to make sure user gets fully logged out
  document.cookie = '_raiseme_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie =
    '_raiseme_skip_static_homepage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  if (location.pathname !== '/login') {
    successfulLoginRedirectPath.set(location.pathname);
    redirect && location.assign('/login');
  }
}

function spaSession<Fields = Record<string, never>>(
  fields: string[] = []
): Promise<UserSession<Fields>> {
  if (!JWTService.userToken.value()) throw new Error('Missing token');
  fields = [...fields, 'next'];
  return UserService.getData<ValidateSessionResponse['user'] & Fields & {next: string}>(
    fields
  ).then((user) => ({
    next: user.next || '/',
    user: {
      ...user,
      id: user._id,
      educationPhase: user.educationPhase,
      type: user.type,
    },
    userIdentifier: getUserIdentifier(user.type, user.educationPhase),
  }));
}

async function webSession<Fields = Record<string, never>>(
  fields: string[] = []
): Promise<UserSession<Fields>> {
  const request = superagent('GET', '/v1/basic/validate-session');
  const queryParams = {fields: fields.join(',')};
  if (JWTService.userToken.value()) {
    queryParams['token'] = JWTService.userToken.value();
  }

  return request.query(qs.stringify(queryParams, {arrayFormat: 'brackets'})).then((response) => {
    response = response.body as ValidateSessionResponse;
    JWTService.updateTokenFromResponse(response);
    return {
      next: response.next,
      user: {
        ...response.user,
        id: response.user._id,
        educationPhase: response.user.educationPhase,
        type: response.user.type,
      },
      userIdentifier: getUserIdentifier(response.user.type, response.user.educationPhase),
    };
  });
}

/**
 * Returns true when paths should be handled by ApplicationLoader
 * @param pathname
 */
export const isOnboardingPath = (pathname: string): boolean =>
  [
    /\/students\/onboarding/,
    /\/parents\/onboarding/,
    /\/educators\/onboarding/,
    /\/college-portal\/reset-password/,
  ].some((regex) => regex.test(pathname));

export default async function validateSession<Fields = Record<string, never>>(
  fields: string[] = [],
  handleRedirect = true
): Promise<UserSession<Fields>> {
  const requiredFields = ['_id', 'type', 'educationPhase'];
  fields = uniq(fields.concat(requiredFields));
  const sessionHandler = vars.isSPA ? spaSession : webSession;
  return new Promise((resolve, reject): void => {
    sessionHandler<Fields>(fields)
      .then((userSession) => {
        setTraceAttributes({
          id: userSession.user.id,
          userIdentifier: userSession.userIdentifier,
        });

        if (
          userSession.next !== '/' &&
          location.pathname !== userSession.next &&
          !isOnboardingPath(userSession.next)
        ) {
          location.assign(userSession.next);
        } else {
          resolve(userSession);
        }
      })
      .catch((error) => {
        handleUnauthorizedRequest(handleRedirect);
        if (!handleRedirect) {
          reject(error);
        }
      });
  });
}

interface ReadyOptions {
  domElementId: string;
  fields?: string[];
}

export function readyAndValidated(
  opts: ReadyOptions,
  callback: (element: HTMLElement, session: UserSession) => void
): void {
  Promise.all([validateSession(opts.fields), getElementOnReady(opts.domElementId)]).then(
    (response) => {
      const [session, element] = response;
      callback(element, session);
    }
  );
}
